import React, { Component } from "react";
import aqmal from "../../assets/team/team_aqmal.png";
import fitri from "../../assets/team/team_fitri.png";
import syafiq from "../../assets/team/team_syafiq.png";
import syai from "../../assets/team/team_syaiful.jpg";
import harith from "../../assets/team/team_rith.png";
import jerai from "../../assets/team/team_azrai.png";
import aiman from "../../assets/team/team_naim.png";

const initData = {
  heading: "Meet our Team Experts",
  headingText: "Building Software with Lean Principles",
  headingTextTwo:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.",
};

const teamData = [
  {
    image: aqmal,
    name: "Aqmal Adnan",
    position: "Founder",
    text: "Keeping the team cafeinated, healthy and wise. Lean Startup Enthusiast",
  },
  {
    image: fitri,
    name: "Fitri Sazali",
    position: "Head of Software Dev",
    text: "Code Tamer, magic maker, transform ideas into a master pieces",
  },
  {
    image: syafiq,
    name: "Syaafiq Asyraf",
    position: "Machine Intelligence & Data Molder",
    text: "Transform raw data into gem, AI Specialized Engineer",
  },
  {
    image: syai,
    name: "Syaiful Fikri",
    position: "UI / UX Architect",
    text: "User empathizer, the Ultimate Visual Architect",
  },
  {
    image: harith,
    name: "Harith Tarmizi",
    position: "Product Manager & Software Developer",
    text: "Love user problem and design solution into workable product. Tech enthusiast",
  },
  {
    image: jerai,
    name: "Faris Azrai",
    position: "Software Developer",
    text: "Coder but also all-rounded person, can switch to any role",
  },
  {
    image: aiman,
    name: "Aiman Naiim",
    position: "AR/VR 3D Artist",
    text: "Creative guy, has superpower to design and develop complex software system",
  },
];

const socialData = [
  {
    iconClass: "fab fa-facebook-f",
  },
  {
    iconClass: "fab fa-twitter",
  },
  {
    iconClass: "fab fa-google-plus-g",
  },
  {
    iconClass: "fab fa-instagram",
  },
  {
    iconClass: "fab fa-linkedin-in",
  },
];

class teamOne extends Component {
  state = {
    initData: {},
    data: [],
    socialData: [],
  };
  componentDidMount() {
    this.setState({
      initData: initData,
      data: teamData,
      socialData: socialData,
    });
  }
  render() {
    return (
      <section id="team" className="section team-area ptb_100">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-6">
              {/* Section Heading */}
              <div className="section-heading text-center">
                <h2 className="text-capitalize">
                  {this.state.initData.heading}
                </h2>
                <p className="d-none d-sm-block mt-4">
                  {this.state.initData.headingText}
                </p>
                {/* <p className="d-block d-sm-none mt-4">{this.state.initData.headingTextTwo}</p> */}
              </div>
            </div>
          </div>
          <div className="row-fluid">
            {/* Team Slider */}
            <div className="team-slider owl-carousel">
              {/* Single Team */}
              {this.state.data.map((item, idx) => {
                return (
                  <div
                    key={`to_${idx}`}
                    className="single-team text-center px-3 py-5 m-4"
                  >
                    {/* Team Photo */}
                    <div className="team-photo mx-auto">
                      <img src={item.image} alt="" />
                    </div>
                    {/* Team Content */}
                    <div className="team-content mt-2 mb-1">
                      <h3 className="team-name mt-3 mb-2">{item.name}</h3>
                      <h5 className="team-post mb-2">{item.position}</h5>
                      <p className="team-description">{item.text}</p>
                      {/* Team Media */}
                      {/* <div className="team-media mt-3">
                                                {this.state.socialData.map((item, idx) => {
                                                    return(
                                                        <a key={`sd_${idx}`} href="#">
                                                            <i className={item.iconClass} />
                                                        </a>
                                                    );
                                                })}
                                            </div> */}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default teamOne;
