import React, { Component } from "react";
import axios from "axios";

const BASE_URL = "https://my-json-server.typicode.com/themeland/appo-json/themeOnePricingSection";

class pricingOne extends Component {
  state = {
    data: {},
    priceData: [],
  };
  componentDidMount() {
    axios
      .get(`${BASE_URL}`)
      .then((res) => {
        this.setState({
          data: res.data,
          priceData: res.data.priceData,
        });
        // console.log(this.state.data)
      })
      .catch((err) => console.log(err));
  }
  render() {
    return (
      <section id="pricing" className="section price-plan-area ptb_100">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-6">
              {/* Section Heading */}
              <div className="section-heading text-center">
                <h2 className="text-capitalize">{this.state.data.heading}</h2>
                <p className="d-none d-sm-block mt-4">{this.state.data.headingText}</p>
                <p className="d-block d-sm-none mt-4">{this.state.data.headingTextTwo}</p>
              </div>
            </div>
          </div>
          {/* <div className="row justify-content-center">
                        <div className="price-plan-wrapper">
                            {this.state.priceData.map((item, idx) => {
                                return(
                                    <div key={`pd_${idx}`} className={item.planClass}>
                                        <div className="price-header d-flex align-items-center justify-content-between my-4 px-3">
                                            <h4 className="plan-title text-uppercase">
                                            {item.planTitle}
                                            </h4>
                                            <div className="plan-cost">
                                                <span className="plan-price">{item.planPrice}</span>
                                                <span className="plan-type text-uppercase">{item.planPackage}</span>
                                            </div>
                                        </div>
                                        <ul className="plan-features pb-3">
                                            <li><i className="icofont-check" />{item.plan_li_1}</li>
                                            <li><i className="icofont-check" />{item.plan_li_2}</li>
                                            <li><i className="icofont-check" />{item.plan_li_2}</li>
                                            <li><i className="icofont-check" />{item.plan_li_4}</li>
                                            <li><i className="icofont-close" />{item.plan_li_5}</li>
                                            <li><i className="icofont-close" />{item.plan_li_6}</li>
                                        </ul>
                                        <div className="plan-select">
                                            <a className="btn btn-bordered text-uppercase" href="#">{item.btnText}</a>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div> */}

          <div class="card pricing-table">
            <div class="pricing-plan d-flex flex-column align-items-center justify-content-between">
              <img
                src="https://s22.postimg.cc/8mv5gn7w1/paper-plane.png"
                alt=""
                class="pricing-img"
              />
              <h2 class="pricing-header"> Startup</h2>
              <ul class="pricing-features">
                <li class="pricing-features-item">1 Lean Solution Architect </li>
                <li class="pricing-features-item">1 Software Developer </li>
                <li class="pricing-features-item">
                  Any Startup looking to build MVP in fastest and leanest way as possible
                </li>
                <li class="pricing-features-item">Lean Startup Consultations</li>
              </ul>
              <span class="pricing-price my-5">RM 6,000 /month</span>
              
            </div>

            <div class="pricing-plan d-flex flex-column align-items-center justify-content-between">
              <img src="https://s28.postimg.cc/ju5bnc3x9/plane.png" alt="" class="pricing-img" />
              <h2 class="pricing-header">Business</h2>
              <ul class="pricing-features">
                <li class="pricing-features-item">1 Lean Solution Architect </li>
                <li class="pricing-features-item">1 Software Developer </li>
                <li class="pricing-features-item">
                  1 UI/UX Designer or 1 Specialised Engineer (eg. AI Engineer, AR/VR Engineer or
                  Data Scientist){" "}
                </li>
                <li class="pricing-features-item">
                  Any serious business looking to digitise their business process in fastest and
                  leanest way as possible{" "}
                </li>
                <li class="pricing-features-item"> Lean Startup Consultations </li>
                <li class="pricing-features-item"> Product Roadmap </li>
                <li class="pricing-features-item"> User Interface & Experience Test </li>
              </ul>
              <span class="pricing-price my-5">RM 10,000 /month</span>
              
            </div>

            <div class="pricing-plan d-flex flex-column align-items-center justify-content-between">
              <img
                src="https://s21.postimg.cc/tpm0cge4n/space-ship.png"
                alt=""
                class="pricing-img"
              />
              <h2 class="pricing-header">Corporate</h2>
              <ul class="pricing-features">
                <li class="pricing-features-item">Dedicated Lean Solution Architect</li>
                <li class="pricing-features-item">Dedicated Software Developer</li>
                <li class="pricing-features-item">Dedicated UI/UX Designer</li>
                <li class="pricing-features-item">Dedicated Specialised Engineer (Eg. AI Engineer, AR/VR Engineer and Data Scientist)</li>
                <li class="pricing-features-item">Any Large Corporations looking to implement Lean Startup Strategy in building their product and solution</li>
              </ul>
              <span class="pricing-price my-5">Contact us for quote</span>
             
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default pricingOne;
