import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/appo-json-2/themeOneFooterSection";

class footer extends Component {
    state = {
        data: {},
        iconList: [ {
            "id": 1,
            "link": "facebook",
            "url" : "https://facebook.com/clazzy.my",
            "iconClass": "fab fa-facebook-f"
          },
          {
            "id": 3,
            "link": "google-plus",
            "url" : "https://instagram.com/clazzy.my",
            "iconClass": "fab fa-instagram"
          },
          {
            "id": 4,
            "link": "vine",
            "url" : "https://wa.me/+60166661267",
            "iconClass": "fab fa-whatsapp"
          }],
        footerList_1: [],
        footerList_2: [],
        footerList_3: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    //iconList: res.data.iconList,
                    footerList_1: res.data.footerList_1,
                    footerList_2: res.data.footerList_2,
                    footerList_3: res.data.footerList_3
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <footer className="section footer-area footer-bg">
                {/* Footer Top */}
                <div className="footer-top ptb_100">
                    <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-lg-3">
                        {/* Footer Items */}
                        <div className="footer-items">
                            {/* Logo */}
                            <a className="navbar-brand" href="#">
                            <img className="logo" src="/img/logo.png" alt="" />
                            </a>
                            <p className="text-white-50 mt-2 mb-3">
                                Clazzy is a startup company and we love to empower other startups by helping them with our solutions, 
                                services and consultations at a cost based basis (zero profit) as we believe networking and relations are a
                                much more valuable assets.
                            </p>
                            {/* Social Icons */}
                            <div className="social-icons d-flex">
                                {this.state.iconList.map((item, idx) => {
                                    return(
                                        <a key={`sil_${idx}`} className={item.link} href={item.url}>
                                            <i className={item.iconClass} />
                                            <i className={item.iconClass} />
                                        </a>
                                    );
                                })}
                            </div>
                        </div>
                        </div>
    
                    </div>
                    </div>
                </div>
                {/* Footer Bottom */}
                <div className="footer-bottom">
                    <div className="container">
                    <div className="row">
                        <div className="col-12">
                        {/* Copyright Area */}
                        <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                            {/* Copyright Left */}
                            <div className="copyright-left text-white-50">&copy; Copyright Clazzy 2020</div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default footer;