import React, { Component } from "react";
import { Row, Col, Card, Form, Button, Container } from "react-bootstrap";
import arrow from "../../assets/arrow.svg";
import arrow2 from "../../assets/arrow2.svg";
import code from '../../assets/code.png' //https://www.flaticon.com/authors/freepik
import flowchart from '../../assets/flow-chart.png' //https://www.flaticon.com/authors/freepik
import linegraph from '../../assets/line-graph.png' //https://www.flaticon.com/authors/phatplus
import meeting from '../../assets/meeting2.png' //https://www.flaticon.com/authors/xnimrodx
import write from '../../assets/write.png' //https://www.flaticon.com/authors/freepik
import design from '../../assets/graphic-design.png' //https://www.flaticon.com/authors/freepik

class HowWork extends Component {
  state = {};
  render() {
    return (
      <section className="howitworks mt-5 mb-5">
        <Container style={{marginTop: "20em"}}>
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-6">
            {/* Section Heading */}
            <div className="section-heading text-center">
              <h2 className="text-capitalize" style={{zIndex: "200"}}>How it works?</h2>
              <p className="d-none d-sm-block mt-4">
                
              </p>
              <p className="d-block d-sm-none mt-4">
                
              </p>
            </div>
          </div>
        </div>
          <Row className="justify-content-between">
            <Col>
              <Row className="tile1">
                <Col lg="12" md="12">
                  <h4 class="my-3"> <img class="card-icon" src={meeting} alt="team management" style={{width:"10%"}}></img>&nbsp;&nbsp;Tell us about your idea</h4>
                  <p className="text-size">
                    You have an idea of product that you want to bring to life? an early definition of the core value proposition could help us to 
                    conduct design and draw up future development map. A series of meetup will help to better refine and focus towards value proposition delivery and a detail product roadmap
                  </p>
                </Col>
              </Row>
              <Row className="tile2">
                <Col lg="12" md="12">
                  {" "}
                  <h4 class="my-3"> <img class="card-icon" src={design} alt="team management" style={{width:"10%"}}></img>&nbsp;&nbsp;Product Design</h4>
                  <p>
                    Once concept of MVP are clarified, a high-fidelity prototype are developed. Our Solution Architect and UX Designer will 
                    gather round with core principle in mind which are user-first approach, future performances and target user friendliness. Next, 
                    a clickable prototype will be developed.
                  </p>
                </Col>
              </Row>
              <Row className="tile3">
                <Col lg="12" md="12">
                  {" "}
                  <h4 class="my-3"> <img class="card-icon" src={linegraph} alt="team management" style={{width:"10%"}}></img>&nbsp;&nbsp;User Input & Feedback</h4>
                  <p className="text-size">
                    The cycle are coming to an end where we get feedback and data collected from the user perspectives and evaluate 
                    whether the product manage to solve the intended pain with some metrics or kpi in places. The entire cycle can be 
                    completed as soon as 1 week!
                  </p>
                </Col>
              </Row>
            </Col>
            <Col lg="3" md="3" className="text-center">
              <img
                className="big-arrow"
                src={arrow2}
                alt="team management"
              ></img>
              <img
                className="mobile-arrow"
                src={arrow}
                alt="team management"
                width="100%"
                style={{ display: "none" }}
              ></img>
            </Col>
            <Col>
              <Row className="tile4">
                <Col lg="12" md="12">
                  {" "}
                  <h4 class="my-3"> <img class="card-icon" src={write} alt="team management" style={{width:"10%"}}></img>&nbsp;&nbsp;Discovery Consultations</h4>
                  <p className="text-size">
                    Found a problem thats worth solving but you doesn't have a clear product vision just yet? Not to worry as we will conduct consultations 
                    that list out the user's needs and empathize their problem at hand. By then, we could understand their needs and ideate with solution and product features.
                    Following Design Sprint, Design Thinking and Lean Startup Methodologies.
                  </p>
                </Col>
              </Row>
              <Row className="tile5">
                <Col lg="12" md="12">
                  {" "}
                  <h4 class="my-3"> <img class="card-icon" src={code} alt="team management" style={{width:"10%"}}></img>&nbsp;&nbsp;Product Development</h4>
                  <p className="text-size">
                    Onces the clickable prototype are user tested and proven able to solve the targeted problem. Its time for 
                    translating the design into working code and deploying it for first user consumption. Cloud hosting and 
                    TDD (Test Driven Development) keep the product stable and scaleable as per user needs.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default HowWork;
