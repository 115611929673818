import React, { Component } from "react";

const faqData = [
    {
        heading: "How can I install this app?",
        content: "The code assets is all yours ! and repository ownership will be transferred upon request.",
        image: "/img/avatar_1.png"
    },
    {
        heading: "Why you can build apps cheaply?",
        content: "Building apps should not be expensive, by developing the apps in stages from MVP to final product features. Its best to be done in the leanest and fastest way as possible! Ensuring a faster Build - Measure - Learn Loop Cycle.",
        image: "/img/avatar_2.png"
    },
    {
        heading: "How about hosting charges?",
        content: "We dont take any hosting fees and charges as we dont provide the solution and we have no interest in making money from double charging you with hosting fees. We usually just request you to create a preferred hosting account for us to use and its billing are incurred directly to you from the hosting provider.",
        image: "/img/avatar_3.png"
    },
    {
        heading: "Is the subscription refundable? ",
        content: "Yes, subscription is fully refundable within 7 days from transaction time.",
        image: "/img/avatar_4.png"
    },
    {
        heading: "Is there is any additional optional charge i should know?",
        content: "Yes, however do note that the charge only apply if there is any request for the services 1. documentation request starting from Rm5k depending on project complexity and type.",
        image: "/img/avatar_4.png"
    },
    
]

class faqOne extends Component {
    state = {
        data: []
    }
    componentDidMount(){
        this.setState({
            data: faqData
        })
    }
  render() {
    return (
      <section className="section faq-area bg-gray ptb_100">
          <div className="row justify-content-center mt-5">
          <div className="col-12 col-md-10 col-lg-6">
            {/* Section Heading */}
            <div className="section-heading text-center">
              <h2 className="text-capitalize" style={{ zIndex: "200" }}>
                Frequently Asked Questions
              </h2>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            {this.state.data.map((item, idx) => {
              return (
                <div key={`fdt_${idx}`} className="col-12 col-md-6 col-lg-4 mt-3">
                  {/* Single Faq */}
                  <div className="single-faq bg-white px-4 py-3 h-100 ">
                    <div className="media">
                      <img className="rounded-circle avatar-sm" src={item.image} alt="" />
                      <div className="media-body align-self-center ml-3">
                        <h5>{item.heading}</h5>
                      </div>
                    </div>
                    <p className="mt-3">{item.content}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default faqOne;
