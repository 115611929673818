import React, { Component } from "react";

const initData = {
  thumb_1: "/img/client3.jpeg",
  thumb_2: "/img/client2.jpg",
};

const reviewData = [
  {
    image: "/img/client_tnb.png",
    name: "Tenaga Nasional Berhad",
    address: "Los Angeles, California",
    icon_1: "fas fa-star",
    icon_2: "fas fa-star",
    icon_3: "fas fa-star",
    icon_4: "fas fa-star",
    icon_5: "far fa-star",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam rem sunt nulla ducimus expedita, incidunt laborum assumenda. Deleniti iste placeat nostrum incidunt rem laudantium, sapiente, cum, molestias unde, quidem labore.",
  },
  {
    image: "/img/client_solvnex.png",
    name: "Solvnex Digital Sdn Bhd",
    address: "Los Angeles, California",
    icon_1: "fas fa-star",
    icon_2: "fas fa-star",
    icon_3: "fas fa-star",
    icon_4: "fas fa-star",
    icon_5: "far fa-star",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam rem sunt nulla ducimus expedita, incidunt laborum assumenda. Deleniti iste placeat nostrum incidunt rem laudantium, sapiente, cum, molestias unde, quidem labore.",
  },
  {
    image: "/img/client_lilypetuna.png",
    name: "LilyPetuna Sdn Bhd",
    address: "Los Angeles, California",
    icon_1: "fas fa-star",
    icon_2: "fas fa-star",
    icon_3: "fas fa-star",
    icon_4: "fas fa-star",
    icon_5: "far fa-star",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam rem sunt nulla ducimus expedita, incidunt laborum assumenda. Deleniti iste placeat nostrum incidunt rem laudantium, sapiente, cum, molestias unde, quidem labore.",
  },
  {
    image: "/img/client_stickii.jpg",
    name: "Stickii Technologies Sdn Bhd",
    address: "Los Angeles, California",
    icon_1: "fas fa-star",
    icon_2: "fas fa-star",
    icon_3: "fas fa-star",
    icon_4: "fas fa-star",
    icon_5: "fas fa-star",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam rem sunt nulla ducimus expedita, incidunt laborum assumenda. Deleniti iste placeat nostrum incidunt rem laudantium, sapiente, cum, molestias unde, quidem labore.",
  },
  {
    image: "/img/client_omr.png",
    name: "Omr Press Sdn Bhd",
    address: "Los Angeles, California",
    icon_1: "fas fa-star",
    icon_2: "fas fa-star",
    icon_3: "fas fa-star",
    icon_4: "fas fa-star",
    icon_5: "far fa-star",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam rem sunt nulla ducimus expedita, incidunt laborum assumenda. Deleniti iste placeat nostrum incidunt rem laudantium, sapiente, cum, molestias unde, quidem labore.",
  },
  {
    image: "/img/client_houztool.png",
    name: "Houztool Resources",
    address: "Los Angeles, California",
    icon_1: "fas fa-star",
    icon_2: "fas fa-star",
    icon_3: "fas fa-star",
    icon_4: "fas fa-star",
    icon_5: "far fa-star",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam rem sunt nulla ducimus expedita, incidunt laborum assumenda. Deleniti iste placeat nostrum incidunt rem laudantium, sapiente, cum, molestias unde, quidem labore.",
  },
  {
    image: "/img/client_throwsend.png",
    name: "Throwsend",
    address: "Los Angeles, California",
    icon_1: "fas fa-star",
    icon_2: "fas fa-star",
    icon_3: "fas fa-star",
    icon_4: "fas fa-star",
    icon_5: "far fa-star",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam rem sunt nulla ducimus expedita, incidunt laborum assumenda. Deleniti iste placeat nostrum incidunt rem laudantium, sapiente, cum, molestias unde, quidem labore.",
  },
  {
    image: "/img/client_tyn.png",
    name: "The Youth Network",
    address: "Los Angeles, California",
    icon_1: "fas fa-star",
    icon_2: "fas fa-star",
    icon_3: "fas fa-star",
    icon_4: "fas fa-star",
    icon_5: "far fa-star",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam rem sunt nulla ducimus expedita, incidunt laborum assumenda. Deleniti iste placeat nostrum incidunt rem laudantium, sapiente, cum, molestias unde, quidem labore.",
  },
  {
    image: "/img/client_megaapps.png",
    name: "Mega Apps",
    address: "Los Angeles, California",
    icon_1: "fas fa-star",
    icon_2: "fas fa-star",
    icon_3: "fas fa-star",
    icon_4: "fas fa-star",
    icon_5: "far fa-star",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam rem sunt nulla ducimus expedita, incidunt laborum assumenda. Deleniti iste placeat nostrum incidunt rem laudantium, sapiente, cum, molestias unde, quidem labore.",
  },
];

class reviewOne extends Component {
  state = {
    initData: {},
    data: [],
  };
  componentDidMount() {
    this.setState({
      initData: initData,
      data: reviewData,
    });
  }
  render() {
    return (
      <section className="section testimonial-area client ptb_100">
        <div className="shapes-container">
          <div className="shape bg-shape" />
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-12 col-md-10 col-lg-6">
            {/* Section Heading */}
            <div className="section-heading text-center">
              <h2 className="text-capitalize" style={{ zIndex: "200" }}>
                Our Client
              </h2>
              <p className="d-none d-sm-block mt-4"></p>
              <p className="d-block d-sm-none mt-4"></p>
            </div>
          </div>
        </div>
        <div className="container text-center">
          <div className="row justify-content-center align-items-center">
            {this.state.data.map((item, idx) => {
              return (
                <div
                  key={`ro_${idx}`}
                  className="single-testimonial col-4 p-3 p-md-5"
                >
                  <img
                    src={item.image}
                    className="mx-auto d-block"
                    width="120px"
                    alt=""
                  />
                  {/* Client Name */}
                  <h3 className="client-name mt-4 mb-2">{item.name}</h3>
                  {/* Client Address */}
                  {/* <h5 className="client-address fw-4">{item.address}</h5> */}
                  {/* Client Rating */}
                  {/* <div className="client-rating mt-2 mb-3">
                        <i className={item.icon_1} />
                        <i className={item.icon_2} />
                        <i className={item.icon_3} />
                        <i className={item.icon_4} />
                        <i className={item.icon_5} />
                      </div> */}
                  {/* <div className="client-description">
                        <div className="client-text">
                          <p>{item.text}</p>
                        </div>
                      </div> */}
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default reviewOne;
